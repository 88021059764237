import Vue from 'vue';
export default Vue.extend({
    data: function () {
        return {
            scrolled: false,
            items: [
                {
                    itemclass: 'logo',
                    route: { name: 'home' },
                    label: '<img src="/images/front/nomadness_logo.png" alt="logo" />'
                },
                {
                    route: { name: 'home' },
                    label: 'Home'
                },
                {
                    route: { name: 'locations' },
                    label: 'Locations'
                },
                {
                    route: { name: 'discounts' },
                    label: 'Discounts'
                },
                {
                    url: '/properties-new',
                    label: 'New Properties'
                },
                {
                    url: '#',
                    label: 'Activities',
                    submenu: [
                        { url: '/activities/mammoth/event-calendar', label: 'Mammoth' },
                        { url: '/activities/steamboat/event-calendar', label: 'Steamboat' },
                        { url: '/activities/ventura/event-calendar', label: 'Ventura' },
                        { url: '/activities/malibu/event-calendar', label: 'Malibu' },
                        { url: '/activities/oceanside/event-calendar', label: 'Oceanside' },
                        { url: '/activities/baltimore/event-calendar', label: 'Baltimore' }
                    ]
                },
                {
                    route: { name: 'About Us' },
                    label: 'About'
                },
                {
                    url: '/contact',
                    label: 'CONTACT'
                },
                {
                    url: '#',
                    label: 'FOLLOW',
                    itemclass: 'social-bar',
                    submenu: [
                        { url: 'https://www.facebook.com/nomadnessrentals/', label: '<i class="fa fa-facebook"></i>' },
                        { url: 'https://twitter.com/Nomadness247', label: '<i class="fa fa-twitter"></i>' },
                        { url: 'http://blog.nomadnessrentals.com/', label: '<i style="font-size: 14px; font-style: normal; font-weight: bold;">blog</i>' },
                        { url: 'https://www.instagram.com/nomadnessrentals/', label: '<i class="fa fa-instagram"></i>' },
                        { url: 'https://www.youtube.com/user/mrboflix', label: '<i class="fa fa-youtube"></i>' },
                    ]
                },
            ]
        };
    },
    methods: {
        handleScroll: function () {
            this.scrolled = window.scrollY > 0;
        }
    },
    mounted: function () {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy: function () {
        window.removeEventListener('scroll', this.handleScroll);
    }
});
