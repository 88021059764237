import Vue from 'vue';
function dateParser(date) {
    if (!date) {
        return null;
    }
    var parts = date.split("/");
    // MM/DD/YYYY
    var dt = new Date(parseInt(parts[2], 10), parseInt(parts[0], 10) - 1, parseInt(parts[1], 10));
    return dt;
}
function dateFormatter(date) {
    if (!date) {
        return '';
    }
    if (typeof date === 'string') {
        date = new Date(date);
    }
    var d = date;
    var value = (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
    return value;
}
export default Vue.extend({
    name: 'search-bar',
    props: ['detailed'],
    data: function () {
        var query = this.$route.query;
        var checkIn = dateParser(query['check-in']);
        var checkOut = dateParser(query['check-out']);
        var selectedRange = null;
        if (checkIn || checkOut) {
            selectedRange = {
                start: checkIn,
                end: checkOut
            };
        }
        return {
            location: query.location || 1,
            guests: query.guests || 0,
            selectedRange: selectedRange,
            bedrooms: 0,
            price: 0,
            moreFiltersVisible: false
        };
    },
    computed: {
        searchbarClass: function () {
            var classes = [];
            classes.push(this.detailed ? 'searchbar-detailed' : 'searchbar-regular');
            if (this.moreFiltersVisible) {
                classes.push('more-filters-visible');
            }
            return classes;
        }
    },
    methods: {
        toggleMoreFilters: function () {
            console.log('toggle');
            this.moreFiltersVisible = !this.moreFiltersVisible;
        },
        dateFormatter: function (date) {
            return dateFormatter(date);
        },
        onSubmit: function () {
            var location = '' + (this.location === 0 ? 1 : this.location);
            var guests = '' + (this.guests === 0 ? 2 : this.guests);
            var bedrooms = '' + (this.bedrooms === 0 ? '' : this.bedrooms);
            var price = '' + (this.price === 0 ? '' : this.price);
            var selectedRange = this.selectedRange;
            var checkIn = selectedRange ? this.dateFormatter(this.selectedRange.start) : null;
            var checkOut = selectedRange ? this.dateFormatter(this.selectedRange.end) : null;
            this.$router.push({
                name: 'rentals-search',
                query: {
                    location: location,
                    guests: guests,
                    bedrooms: bedrooms,
                    price: price,
                    'check-in': checkIn,
                    'check-out': checkOut
                }
            });
        },
        onChange: function () {
            if (!this.detailed) {
                return;
            }
            this.onSubmit();
        }
    },
});
