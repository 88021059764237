var carousel = require('vue-carousel');
import Vue from 'vue';
export default Vue.extend({
    name: 'videos',
    components: { Carousel: carousel.Carousel, Slide: carousel.Slide },
    props: ['items'],
    data: function () {
        var videos = [];
        for (var _i = 0, _a = this.items; _i < _a.length; _i++) {
            var item = _a[_i];
            videos.push({
                id: item.video,
                shown: false
            });
        }
        return { videos: videos };
    },
    methods: {
        setShown: function (item) {
            item.shown = true;
        },
        thumbnail: function (video_id) {
            return "//i.ytimg.com/vi/" + video_id + "/hqdefault.jpg";
        },
        iframeURL: function (video_id) {
            return "//www.youtube.com/embed/" + video_id + "?autoplay=1&autohide=2&border=0&wmode=opaque&enablejsapi=1&controls=0&showinfo=0&rel=0";
        },
    }
});
