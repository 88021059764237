import Vue from 'vue';
export default Vue.extend({
    name: 'reservation-insurance',
    props: ['quote', 'quotePrice'],
    computed: {
        travelInsuranceFlat: function () {
            return this.quote.Listing.damageInsurance;
        }
    },
    methods: {
        cost: function () {
            return 0;
        },
        travelInsuranceCost: function () {
            var price = this.quote ? this.quotePrice.quotePrice : 0;
            var percent = this.quotePrice.travelInsuranceCostPercentage / 100;
            return (price * percent).toFixed(2);
        },
        setInsuranceCoverage: function (value) {
            this.quote.InsuranceCoverage = value;
            this.$emit('quote-updated', this.quote);
        }
    }
});
