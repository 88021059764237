import Vue from 'vue';
import store, * as storeAPI from '@/store/index.ts';
var pageComponent = Vue.extend({
    methods: {
        imageURL: function (pictureId) {
            // TODO: fix this hack
            // return `/uploads/images/${pictureId}`
            return "https://www.nomadnessrentals.com/uploads/images/" + pictureId;
        },
        setTitle: function (title) {
            storeAPI.setTitle(store, title);
        },
        setBreadcrumbs: function (crumbs) {
            storeAPI.setBreadcrumbs(store, crumbs);
        },
        setIsLoading: function (loading) {
            storeAPI.setIsLoading(store, loading);
        },
    },
    computed: {
        breadcrumbs: function () {
            return storeAPI.breadcrumbs(store);
        },
        title: function () {
            return storeAPI.title(store);
        },
        isLoading: function () {
            return store.state.isLoading;
        },
    }
});
export default pageComponent;
