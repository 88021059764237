import Vue from 'vue';
export default Vue.extend({
    name: 'reservation-availability',
    props: ['quote'],
    data: function () {
        var date = this.quote.Listing.availabilityFirstDate;
        var bookedDates = [];
        for (var _i = 0, _a = this.quote.Listing.availability; _i < _a.length; _i++) {
            var available = _a[_i];
            if (!available) {
                bookedDates.push(date);
            }
            date = new Date(date.valueOf());
            date.setDate(date.getDate() + 1);
        }
        return {
            bookedDates: bookedDates,
            dateFormat: {
                title: 'MMM YYYY',
                weekdays: 'W',
                navMonths: 'MMM',
                input: ['L', 'MM/DD/YYYY', 'MM/DD/YYYY'],
                dayPopover: 'L',
                data: ['L', 'MM/DD/YYYY', 'MM/DD/YYYY'] // For attribute dates
            },
            selectedRange: null,
            adults: 2,
            children: 0,
            pets: 0,
            discount: '',
        };
    },
    methods: {
        validate: function () {
            // TODO: call api to check *ACTUAL* availability and validate discount
            console.log('validate');
            var quote = this.quote;
            var range = this.selectedRange;
            quote.Arrive = range ? range.start : null;
            quote.Depart = range ? range.end : null;
            quote.NumGuests = this.adults + this.children;
            this.$emit('quote-updated', quote);
            return quote;
        },
        formatDate: function (date) {
            if (!date) {
                return '';
            }
            if (typeof date === 'string') {
                date = new Date(date);
            }
            var d = date;
            var value = (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
            return value;
        },
    }
});
