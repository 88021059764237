var carousel = require('vue-carousel');
import Vue from 'vue';
export default Vue.extend({
    name: 'featured-properties',
    components: {
        Carousel: carousel.Carousel,
        Slide: carousel.Slide,
    },
    props: ['items'],
});
