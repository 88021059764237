import Vue from 'vue';
export default Vue.extend({
    name: 'image-slider',
    data: function () {
        return {
            images: [
                'https://s3.amazonaws.com/nmr-cdn/img/nomadness/home/1.jpg',
                'https://s3.amazonaws.com/nmr-cdn/img/nomadness/home/2.jpg',
                'https://s3.amazonaws.com/nmr-cdn/img/nomadness/home/3.jpg',
                'https://s3.amazonaws.com/nmr-cdn/img/nomadness/home/4.jpg',
                'https://s3.amazonaws.com/nmr-cdn/img/nomadness/home/5.jpg',
            ],
            showingBG1: true,
            image1: 0,
            image2: 1,
            timeout: 10 * 1000,
            timer: 0,
        };
    },
    computed: {
        backgroundClass: function () { return this.showingBG1 ? "visible-1" : "visible-2"; },
        style1: function () { return "background-image: url(" + this.images[this.image1] + ");"; },
        style2: function () { return "background-image: url(" + this.images[this.image2] + ");"; },
    },
    methods: {
        updateImage: function () {
            if (!this.images) {
                return;
            }
            if (this.showingBG1) {
                this.image2 = (this.image2 + 2) % this.images.length;
            }
            else {
                this.image1 = (this.image1 + 2) % this.images.length;
            }
            this.showingBG1 = !this.showingBG1;
        },
    },
    mounted: function () {
        var _this = this;
        this.timer = window.setInterval(function () { _this.updateImage(); }, this.timeout);
    },
    beforeDestroy: function () {
        window.clearInterval(this.timer);
    },
});
