var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import searchBar from '@/components/search-bar.vue';
import featuredProperties from './featured-properties.vue';
import properties from './properties.vue';
import PageComponent from '@/lib/page-component.ts';
import store, { loadLocations, loadListings } from '@/store';
function routeUpdate(to, from, next) {
    return __awaiter(this, void 0, Promise, function () {
        var query, locationID, guests, bedrooms, price, qIn, qOut, checkIn, checkOut, listings;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    query = to.query;
                    locationID = query.location || "1";
                    guests = query.guests || '0';
                    bedrooms = query.bedrooms || '';
                    price = query.price || '';
                    qIn = query['check-in'];
                    qOut = query['check-out'];
                    checkIn = (qIn && qIn.length) ? new Date(qIn) : null;
                    checkOut = (qOut && qOut.length) ? new Date(qOut) : null;
                    if (locationID === "0") {
                        locationID = "1";
                    }
                    return [4 /*yield*/, loadListings(store)];
                case 1:
                    _a.sent();
                    listings = store.getters.listings({
                        LocationID: +locationID,
                        NumGuests: +guests,
                        Arrive: checkIn,
                        Depart: checkOut,
                        Bedrooms: bedrooms,
                        Price: price,
                    });
                    next(function (vm) {
                        var $vm = vm;
                        var $data = vm.$data;
                        $data.locationID = locationID;
                        $data.guests = guests;
                        $data.listings = listings;
                        $data.checkIn = checkIn;
                        $data.checkOut = checkOut;
                    });
                    return [2 /*return*/];
            }
        });
    });
}
export default PageComponent.extend({
    name: 'search-results',
    components: { featuredProperties: featuredProperties, searchBar: searchBar, properties: properties },
    beforeRouteUpdate: function (to, from, next) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                routeUpdate(to, from, function (callback) {
                    callback(_this);
                    next();
                });
                return [2 /*return*/];
            });
        });
    },
    beforeRouteEnter: function (to, from, next) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                routeUpdate(to, from, next);
                return [2 /*return*/];
            });
        });
    },
    data: function () {
        var listings = [];
        return {
            currentPage: 1,
            pageSize: 12,
            locationID: '',
            guests: '',
            checkIn: '',
            checkOut: '',
            listings: listings
        };
    },
    computed: {
        listingsCurrentPage: function () {
            return this.listings.slice((this.currentPage - 1) * this.pageSize, (this.currentPage - 0) * this.pageSize);
        },
        featuredListings: function () {
            return this.listings.filter(function (listing) {
                return listing.options.featured;
            });
        }
    },
    methods: {
        paginate: function (page) {
            this.currentPage = page;
        },
        updateHeader: function () {
            return __awaiter(this, void 0, void 0, function () {
                var location;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, loadLocations(store)];
                        case 1:
                            _a.sent();
                            location = store.getters.location(+this.locationID);
                            if (!location) {
                                return [2 /*return*/];
                            }
                            this.setTitle(location.slug + " Rentals");
                            this.setBreadcrumbs([
                                { label: 'Home', route: { name: 'home' } },
                                { label: 'Properties' },
                                { label: location.slug,
                                    route: {
                                        name: 'rentals-search',
                                        query: { location: this.locationID }
                                    },
                                },
                                { label: 'Rentals' },
                            ]);
                            return [2 /*return*/];
                    }
                });
            });
        }
    },
    watch: {
        'locationID': 'updateHeader'
    },
    mounted: function () {
        this.updateHeader();
    },
});
