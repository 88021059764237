import Vue from 'vue';
export default Vue.extend({
    name: 'reservation-preview',
    props: ['quote', 'quotePrice'],
    computed: {
        days: function () {
            if (!this.quote.Arrive || !this.quote.Depart) {
                return 0;
            }
            // https://stackoverflow.com/questions/2627473
            return Math.round(Math.abs((this.quote.Depart.getTime() -
                this.quote.Arrive.getTime()) / (24 * 60 * 60 * 1000)));
        },
        nights: function () {
            return this.days + 1;
        }
    }
});
