import Vue from 'vue';
export default Vue.extend({
    name: 'breadcrumbs',
    props: ['breadcrumbs'],
    watch: {
        '$route.meta.crumbs': 'reload'
    },
    methods: {
        reload: function () {
            this.$forceUpdate();
        }
    },
    computed: {
        crumbsClass: function () {
            if (this.$route.meta.title) {
                return '';
            }
            else {
                return 'crumbs-details';
            }
        },
    },
});
