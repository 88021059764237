import Vue from 'vue';
export default Vue.extend({
    name: 'activity-list-item',
    props: ['activity'],
    data: function () {
        return {
            dailyEventsMemo: {},
            calendarStyles: {
                wrapper: {
                    backgroundColor: 'transparent',
                    paneWidth: 'auto',
                    color: 'black',
                },
            },
            calendarFormats: {
                title: 'MMMM YYYY',
                weekdays: 'WWW',
                navMonths: 'MMM',
                input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
                dayPopover: 'L',
            },
            calendarAttributes: [
                {
                    popover: {
                        label: 'You just hovered over today\'s date!',
                    },
                    key: 'today',
                    highlight: {
                        backgroundColor: '#ff8080',
                    },
                    dates: new Date(2018, 0, 1)
                }
            ]
        };
    },
    methods: {
        formatDate: function (date) {
            if (!date) {
                return '';
            }
            if (typeof date === 'string') {
                date = new Date(date);
            }
            var d = date;
            var value = (d.getMonth() + 1) + "/" + d.getDate() + "/" + d.getFullYear();
            return value;
        },
        moreEventsLabel: function (date) {
            var events = this.dailyEvents(date);
            if (events.length <= 1) {
                return '&nbsp;';
            }
            return "+" + (events.length - 1);
        },
        eventLabel: function (date) {
            var events = this.dailyEvents(date);
            if (events.length == 0) {
                return '';
            }
            var title = events[0].title;
            if (title.length > 20) {
                title = title.substring(0, 20) + "...";
            }
            return title;
        },
        calendarDayClass: function (date) {
            return this.dailyEvents(date).length > 0 ? 'has-events' : '';
        },
        dailyEvents: function (date) {
            var time = date.getTime();
            var memo = this.dailyEventsMemo[time];
            if (memo) {
                return memo;
            }
            var results = this.activity.subPages.filter(function (event) {
                var start = new Date(event.event_date);
                var end = new Date(event.event_end_date);
                return time >= start.getTime() && time <= end.getTime();
            });
            this.dailyEventsMemo[time] = results;
            return results;
        },
        imageURL: function (pictureId) {
            // TODO: fix this hack
            // return `/uploads/images/${pictureId}`
            return "https://www.nomadnessrentals.com/uploads/images/" + pictureId;
        },
        shortenBody: function (html) {
            var doc = new DOMParser().parseFromString(html, 'text/html');
            var result = (doc.body.textContent || "").replace(/&nbsp;/gi, ' ').replace(' ', ' ').substr(0, 183) + "...";
            return result;
        },
    }
});
