var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import api from '@/api';
import * as quoteLib from '@/lib/quote';
import { getStoreAccessors } from "vuex-typescript";
var state = {
    isLoading: false,
    title: '',
    breadcrumbs: [],
    listings: [],
    locationDetails: {},
    locationActivities: {},
    discountDetails: {},
    locations: undefined,
    homepageData: undefined,
    aboutpageData: undefined,
    contactpageData: undefined
};
var store = {
    namespaced: true,
    state: state,
    getters: {
        location: function (state) {
            return function location(id) {
                for (var _i = 0, _a = state.locations.subPages; _i < _a.length; _i++) {
                    var l = _a[_i];
                    if (l.id === id) {
                        return l;
                    }
                }
                return null;
            };
        },
        listings: function (state) {
            return function listings(_a) {
                var LocationID = _a.LocationID, NumGuests = _a.NumGuests, Arrive = _a.Arrive, Depart = _a.Depart, Price = _a.Price, Bedrooms = _a.Bedrooms;
                var result = state.listings.filter(function (Listing) {
                    if (Listing.locationid !== LocationID) {
                        return false;
                    }
                    if (Listing.rooms.sleeps < NumGuests) {
                        return false;
                    }
                    if (typeof Price !== 'undefined') {
                        // TODO: filter by price
                    }
                    if (typeof Bedrooms !== 'undefined') {
                        if (Listing.rooms.bedrooms < Bedrooms) {
                            return false;
                        }
                    }
                    if (!quoteLib.IsAvailable({ Listing: Listing, Arrive: Arrive, Depart: Depart })) {
                        return false;
                    }
                    return true;
                });
                result.forEach(function (Listing) {
                    Listing.quote = quoteLib.CalcPropertyPriceQuote({
                        Listing: Listing,
                        Arrive: Arrive,
                        Depart: Depart
                    });
                });
                return result;
            };
        },
        listing: function (state) {
            return function listing(id) {
                for (var _i = 0, _a = state.listings; _i < _a.length; _i++) {
                    var l = _a[_i];
                    if (l.id === id) {
                        return l;
                    }
                }
                return null;
            };
        },
        breadcrumbs: function (state) {
            return state.breadcrumbs;
        },
        title: function (state) {
            return state.title;
        },
    },
    mutations: {
        setIsLoading: function (state, loading) {
            state.isLoading = loading;
        },
        setBreadcrumbs: function (state, breadcrumbs) {
            state.breadcrumbs = breadcrumbs;
        },
        setTitle: function (state, title) {
            state.title = title;
        },
        setLocations: function (state, locations) {
            store.state.locations = locations;
        },
        setListings: function (state, listings) {
            store.state.listings = listings;
        },
        addLocationDetail: function (state, location) {
            store.state.locationDetails[location.id] = location;
        },
        addLocationActivity: function (state, activity) {
            store.state.locationActivities[activity.id] = activity;
        },
        addDiscount: function (state, discount) {
            store.state.discountDetails[discount.id] = discount;
        },
        setHomepageData: function (state, data) {
            store.state.homepageData = data;
        },
        setAboutPageData: function (state, data) {
            store.state.aboutpageData = data;
        },
        setContactPageData: function (state, data) {
            store.state.contactpageData = data;
        }
    },
    actions: {
        loadListings: function (context) {
            return __awaiter(this, void 0, Promise, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!(context.state.listings.length === 0)) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['setListings'];
                            return [4 /*yield*/, api.listings()];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            _d.label = 2;
                        case 2: return [2 /*return*/, context.state.listings];
                    }
                });
            });
        },
        loadLocations: function (context) {
            return __awaiter(this, void 0, Promise, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!(typeof context.state.locations === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['setLocations'];
                            return [4 /*yield*/, api.locations()];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            _d.label = 2;
                        case 2: return [2 /*return*/, context.state.locations];
                    }
                });
            });
        },
        loadLocationBySlug: function (context, _a) {
            var slug = _a.slug;
            return __awaiter(this, void 0, Promise, function () {
                var locations, id, _i, _b, location_1, location;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0: return [4 /*yield*/, context.dispatch('loadLocations')];
                        case 1:
                            locations = (_c.sent());
                            id = -1;
                            for (_i = 0, _b = locations.subPages; _i < _b.length; _i++) {
                                location_1 = _b[_i];
                                if (location_1.slug == slug) {
                                    id = location_1.id;
                                }
                            }
                            if (id === -1) {
                                return [2 /*return*/, null];
                            }
                            return [4 /*yield*/, context.dispatch('loadLocation', { id: id })
                                // TODO: get rid of hack
                            ];
                        case 2:
                            location = _c.sent();
                            // TODO: get rid of hack
                            location.slug = slug;
                            return [2 /*return*/, location];
                    }
                });
            });
        },
        loadLocation: function (context, _a) {
            var id = _a.id;
            return __awaiter(this, void 0, Promise, function () {
                var location, _i, _b, activity;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            location = context.state.locationDetails[id];
                            if (!(typeof location === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            return [4 /*yield*/, api.location(id)];
                        case 1:
                            location = _c.sent();
                            context.commit('setIsLoading', false);
                            // TODO: get rid of hack
                            location.id = id;
                            for (_i = 0, _b = location.subPages; _i < _b.length; _i++) {
                                activity = _b[_i];
                                // TODO: get rid of hack
                                activity.slug = activity.slug.replace('activities-single', 'activities');
                                if (!activity.slug.startsWith('/')) {
                                    activity.slug = "/" + activity.slug;
                                }
                            }
                            context.commit('addLocationDetail', location);
                            _c.label = 2;
                        case 2: return [2 /*return*/, location];
                    }
                });
            });
        },
        loadLocationActivity: function (context, _a) {
            var locationId = _a.locationId, id = _a.id;
            return __awaiter(this, void 0, Promise, function () {
                var location, activity, _i, _b, subpage;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0: return [4 /*yield*/, context.dispatch('loadLocation', { id: locationId })];
                        case 1:
                            location = _c.sent();
                            activity = context.state.locationActivities[id];
                            if (!(typeof activity === 'undefined')) return [3 /*break*/, 3];
                            context.commit('setIsLoading', true);
                            return [4 /*yield*/, api.locationActivity(locationId, id)];
                        case 2:
                            activity = _c.sent();
                            if (activity.subPages) {
                                for (_i = 0, _b = activity.subPages; _i < _b.length; _i++) {
                                    subpage = _b[_i];
                                    if (!subpage.slug.startsWith('/')) {
                                        subpage.slug = "/" + subpage.slug;
                                    }
                                }
                            }
                            context.commit('setIsLoading', false);
                            context.commit('addLocationActivity', activity);
                            _c.label = 3;
                        case 3: return [2 /*return*/, activity];
                    }
                });
            });
        },
        loadHomepage: function (context) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!(typeof context.state.homepageData === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['setHomepageData'];
                            return [4 /*yield*/, api.homepage()];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            _d.label = 2;
                        case 2: return [2 /*return*/, context.state.homepageData];
                    }
                });
            });
        },
        loadAboutPage: function (context) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!(typeof context.state.aboutpageData === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['setAboutPageData'];
                            return [4 /*yield*/, api.aboutpage()];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            _d.label = 2;
                        case 2: return [2 /*return*/, context.state.aboutpageData];
                    }
                });
            });
        },
        loadContactPage: function (context) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!(typeof context.state.contactpageData === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['setContactPageData'];
                            return [4 /*yield*/, api.contactpage()];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            _d.label = 2;
                        case 2: return [2 /*return*/, context.state.contactpageData];
                    }
                });
            });
        },
        loadDiscount: function (context, id) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, _b, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            if (!(typeof context.state.discountDetails[id] === 'undefined')) return [3 /*break*/, 2];
                            context.commit('setIsLoading', true);
                            _b = (_a = context).commit;
                            _c = ['addDiscount'];
                            return [4 /*yield*/, api.discount(id)];
                        case 1:
                            _b.apply(_a, _c.concat([_d.sent()]));
                            context.commit('setIsLoading', false);
                            _d.label = 2;
                        case 2: return [2 /*return*/, context.state.discountDetails[id]];
                    }
                });
            });
        }
    },
};
// function datediff(first: Date, second: Date) {
//   // Take the difference between the dates and divide by milliseconds per day.
//   // Round to nearest whole number to deal with DST.
//   return Math.round((second.valueOf() - first.valueOf())/(1000*60*60*24));
// }
var _a = getStoreAccessors(""), commit = _a.commit, read = _a.read, dispatch = _a.dispatch;
/* Fix for old browsers where function.name isn't well-supported
 * We manually set the "name" of each store function, so that Vuex can identify
 * the objects even in a minified environment
 * */
var collections = [
    store.getters,
    store.mutations,
    store.actions,
];
for (var _i = 0, collections_1 = collections; _i < collections_1.length; _i++) {
    var collection = collections_1[_i];
    for (var key in collection) {
        var obj = collection[key]._vuexKey = key;
    }
}
export var location = read(store.getters.location);
export var breadcrumbs = read(store.getters.breadcrumbs);
export var title = read(store.getters.title);
export var setBreadcrumbs = commit(store.mutations.setBreadcrumbs);
export var setTitle = commit(store.mutations.setTitle);
export var setIsLoading = commit(store.mutations.setIsLoading);
export var loadLocations = dispatch(store.actions.loadLocations);
export var loadListings = dispatch(store.actions.loadListings);
export var loadLocationBySlug = dispatch(store.actions.loadLocationBySlug);
export var loadLocation = dispatch(store.actions.loadLocation);
export var loadLocationActivity = dispatch(store.actions.loadLocationActivity);
export var loadHomepage = dispatch(store.actions.loadHomepage);
export var loadAboutPage = dispatch(store.actions.loadAboutPage);
export var loadContactPage = dispatch(store.actions.loadContactPage);
export var loadDiscount = dispatch(store.actions.loadDiscount);
export default new Vuex.Store(store);
