import Vue from 'vue';
export default Vue.extend({
    name: 'reservation-quote',
    props: ['quotePrice'],
    methods: {
        formatPrice: function (n) {
            return '$' + (n.toFixed(2));
        },
    }
});
