import Vue from 'vue';
import activityEventCalendar from './activity-event-calendar.vue';
export default Vue.extend({
    name: 'activity-list-item',
    props: ['activity'],
    components: { activityEventCalendar: activityEventCalendar },
    data: function () {
        return {
            modalImg: 'N/A',
            modalImgIndex: 0
        };
    },
    methods: {
        imageURL: function (pictureId) {
            // TODO: fix this hack
            // return `/uploads/images/${pictureId}`
            return "https://www.nomadnessrentals.com/uploads/images/" + pictureId;
        },
        shortenBody: function (html) {
            var doc = new DOMParser().parseFromString(html, 'text/html');
            var result = (doc.body.textContent || "").replace(/&nbsp;/gi, ' ').replace(' ', ' ').substr(0, 183) + "...";
            return result;
        },
        toggleModal: function (ndx) {
            var img = this.activity.images[ndx];
            this.$modal.show('gallery-image');
            this.$data.modalImg = this.imageURL(img);
        },
    }
});
