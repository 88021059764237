var carousel = require('vue-carousel');
import Vue from 'vue';
import featuredPropertySingle from './single.vue';
export default Vue.extend({
    name: 'featured-properties',
    components: {
        Carousel: carousel.Carousel,
        Slide: carousel.Slide,
        featuredPropertySingle: featuredPropertySingle
    },
    props: ['items'],
    computed: {
        items_grouped: function () {
            if (!this.items) {
                return [];
            }
            var results = [];
            for (var i = 0; i < this.items.length; i += 2) {
                results.push(this.items.slice(i, i + 2));
            }
            return results;
        }
    }
});
