import Vue from 'vue';
import propertyImage from './image.vue';
import propertyInfo from './info.vue';
export default Vue.extend({
    name: 'featured-property-single',
    components: { propertyImage: propertyImage, propertyInfo: propertyInfo },
    props: ['item', 'order'],
    computed: {
        imageClass: function () {
            return this.order === 'left' ? 'image-boxed-top' : 'image-bottom';
        },
        infoClass: function () {
            return this.order === 'left' ? 'boxed-top' : 'boxed-bottom';
        },
    }
});
