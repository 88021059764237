import Vue from 'vue';
import imageSlider from '@/components/image-slider.vue';
import searchBar from '@/components/search-bar.vue';
import breadcrumbs from '@/components/breadcrumbs.vue';
export default Vue.extend({
    components: { searchBar: searchBar, imageSlider: imageSlider, breadcrumbs: breadcrumbs },
    props: ['title', 'breadcrumbs'],
    data: function () {
        return {
            maxHeightPage: 450,
            maxHeightHome: 925,
            width: 1925,
            height: 925
        };
    },
    methods: {
        onResize: function () {
            this.width = window.innerWidth;
            this.height = Math.min(this.maxHeight, window.innerHeight + 120);
        },
        reload: function () {
            this.$forceUpdate();
            this.onResize();
        }
    },
    computed: {
        isHome: function () {
            return this.$route.name === 'home';
        },
        maxHeight: function () {
            return this.isHome ? this.maxHeightHome : this.maxHeightPage;
        },
        style: function () {
            return "\n        width: " + this.width + "px;\n        height: " + this.height + "px;\n      ";
        },
    },
    watch: {
        '$route': 'reload'
    },
    mounted: function () {
        this.onResize();
        window.addEventListener('resize', this.onResize);
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.onResize);
    },
});
