import Vue from 'vue';
import VueRouter from 'vue-router';
import app from '@/app.vue';
import root from '@/components/home/index.vue';
import search from '@/components/search/index.vue';
import newlistings from '@/components/search/new-properties.vue';
import listing from '@/components/listing/index.vue';
import location from '@/components/location/index.vue';
import locations from '@/components/location/list.vue';
import locationActivity from '@/components/location/activity.vue';
import discount from '@/components/discount/index.vue';
import discounts from '@/components/discount/list.vue';
import about from '@/components/about/index.vue';
import contact from '@/components/home/contact.vue';
// import teamMember from '@/components/about/team-member.vue'
export default function () {
    Vue.use(VueRouter);
    var router = new VueRouter({
        mode: 'history',
        scrollBehavior: function (to, from, savedPosition) {
            return { x: 0, y: 0 };
        },
        routes: [
            {
                path: '/',
                component: app,
                children: [
                    { path: '/', name: 'home', component: root },
                    { path: '/location/:id', name: 'location', component: location },
                    { path: '/locations', name: 'locations', component: locations },
                    { path: '/rentals-search', name: 'rentals-search', component: search, props: true },
                    { path: '/properties-new', name: 'properties-new', component: newlistings, props: true, },
                    { path: '/properties-rentals/:id/:slug', name: 'listing', component: listing, props: true, },
                    { path: '/discounts/:id', name: 'discount', component: discount, props: true, },
                    { path: '/discounts', name: 'discounts', component: discounts, props: true, },
                    { path: '/activities/:location/:activity/:slug?', name: 'Location Activity', component: locationActivity },
                    { path: '/about-us', name: 'About Us', component: about },
                    { path: '/contact', name: 'Contact Us', component: contact },
                ]
            },
        ]
    });
    return router;
}
