var carousel = require('vue-carousel');
import Vue from 'vue';
import * as types from '@/types';
import { getPageImageURL } from '@/api';
export default Vue.extend({
    name: 'testimonials',
    components: { Carousel: carousel.Carousel, Slide: carousel.Slide },
    props: ['items'],
    methods: {
        getImageURL: function (item) {
            return getPageImageURL(item.id, types.ImageSize.large);
        },
    }
});
