import Vue from 'vue';
import availability from './availability.vue';
import insurance from './insurance.vue';
import reservationQuote from './quote.vue';
import preview from './preview.vue';
import * as quoteLib from '@/lib/quote';
export default Vue.extend({
    name: 'reservation',
    props: ['listing'],
    components: { availability: availability, insurance: insurance, preview: preview, reservationQuote: reservationQuote },
    data: function () {
        var data = {
            quote: {
                Listing: this.listing,
                NumGuests: 0,
                InsuranceCoverage: '',
                DiscountCode: ''
            },
            quotePrice: null,
            modalOpened: false,
        };
        data.quotePrice = quoteLib.CalcPropertyPriceQuote(data.quote);
        return data;
    },
    mounted: function () {
        var modal = this.$refs.modalContainer;
        document.body.insertBefore(modal, document.body.firstChild);
    },
    methods: {
        onComplete: function () {
            this.modalOpened = false;
        },
        updateQuote: function (quote) {
            this.quote = quote;
            this.quotePrice = quoteLib.CalcPropertyPriceQuote(quote);
        },
        validate: function (ref) {
            var step = this.$refs[ref];
            var quote = step.validate();
            this.quote = quote;
            if (!this.quotePrice) {
                return false;
            }
            var errors = this.quotePrice.errors;
            if (!errors) {
                return false;
            }
            return errors.length == 0;
        },
        isLastStep: function () {
            if (this.$refs.wizard) {
                return this.$refs.wizard.isLastStep;
            }
            return false;
        }
    }
});
