import Vue from 'vue';
import Meta from 'vue-meta';
import VModal from 'vue-js-modal';
import setupRouter from '@/router/index.ts';
import store from '@/store';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import '@/css/front/style.css';
import '@/css/front/theme-responsive.css';
if (!String.prototype.endsWith) {
    String.prototype.endsWith = function (search, this_len) {
        if (this_len === undefined || this_len > this.length) {
            this_len = this.length;
        }
        return this.substring(this_len - search.length, this_len) === search;
    };
}
Vue.component('pagination', require('vue-pagination-2').Pagination);
Vue.directive('sticky', require('vue-sticky').default);
Vue.use(Meta);
Vue.use(require('vue-scrollto'), {
    offset: -100
});
var VueFormWizard = require('vue-form-wizard');
Vue.component('form-wizard', VueFormWizard.FormWizard);
Vue.component('tab-content', VueFormWizard.TabContent);
Vue.component('wizard-button', VueFormWizard.WizardButton);
Vue.component('wizard-step', VueFormWizard.WizardStep);
Vue.use(VModal);
Vue.use(require('vue-lazyload'));
Vue.use(require('vue2-google-maps'), {
    load: { key: 'AIzaSyBXFnHfYDN4dxh2CVduMWmWl2gkh0CoLG4', },
});
var router = setupRouter();
// eslint-disable-next-line no-new
var vueApp = new Vue({
    el: '#app',
    router: router,
    store: store,
    render: function (h) { return h('router-view'); }
});
var calendarLib = require('v-calendar');
import 'v-calendar/lib/v-calendar.min.css';
calendarLib.setupCalendar({
    firstDayOfWeek: 1,
});
Vue.component('v-calendar', calendarLib.Calendar);
Vue.component('v-datepicker', calendarLib.DatePicker);
export default vueApp;
