import * as types from '@/types';
import { getImageURL } from '@/api';
export function parseListing(listing) {
    var properties = [];
    var amenityTypes = [
        [listing.pool, "pool"],
        [listing.spa, "spa"],
        [listing.wiFi, "wiFi"],
        [listing.ac, "ac"],
        [listing.pets, "pets"],
        [listing.nearSki, "ski"],
        [listing.nearGolf, "golf"],
        [listing.nearFishing, "fishing"],
        [listing.nearVillage, "village"],
    ];
    for (var _i = 0, amenityTypes_1 = amenityTypes; _i < amenityTypes_1.length; _i++) {
        var amenity = amenityTypes_1[_i];
        if (amenity[0]) {
            properties.push(amenity[1]);
        }
    }
    var propertyPriceClass = "Budget";
    if (listing.property_Type_Price == 2) {
        propertyPriceClass = "Midrange";
    }
    if (listing.property_Type_Price == 3) {
        propertyPriceClass = "Luxury";
    }
    var images = (listing.images || []).map(function (image) {
        return { listingId: listing.id, imageId: image.ImgNum, fileName: image.FileName };
    });
    var thumbnail = (images.length == 0) ? getImageURL({
        listingId: listing.id,
        imageId: 1,
        fileName: '1.jpg'
    }, types.ImageSize.large) : getImageURL({
        listingId: listing.id,
        imageId: images[0].imageId,
        fileName: images[0].fileName
    }, types.ImageSize.large);
    return {
        id: +listing.id,
        locationid: +listing.locationid,
        pid: +listing.pid,
        title: listing.title,
        body: listing.body,
        details: listing.details,
        thumbnail: thumbnail,
        href: { name: 'listing', params: { id: +listing.id, slug: listing.slug } },
        availability: GetAvailability(listing.availability),
        availabilityFirstDate: listing.availabilityFirstDate,
        damageInsurance: listing.damageInsurance,
        serviceTax: listing.serviceTax,
        lodgingTax: listing.lodgingTax,
        images: images,
        type: propertyPriceClass,
        activated: new Date(listing.activate_Date),
        position: {
            lat: listing.lat,
            lng: listing.lon
        },
        options: {
            featured: listing.featured,
            discounted: (listing.numDiscounts > 0),
            petsAllowed: listing.pets
        },
        rooms: {
            bedrooms: listing.bedrooms,
            bathrooms: listing.bathrooms,
            sleeps: listing.sleeps,
        },
        properties: properties,
        rates: listing.rates,
        fees: listing.fees
    };
}
function datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second.valueOf() - first.valueOf()) / (1000 * 60 * 60 * 24));
}
function addDays(date, days) {
    var dat = new Date(date.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
}
function GetAvailability(base64str) {
    if (base64str == null)
        return null;
    var result = [];
    var n = 0;
    var data = atob(base64str);
    for (var i = 0; i < data.length; i++) {
        n = data.charCodeAt(i);
        result.push((n & 0x1) == 0);
        n = n >> 1;
        result.push((n & 0x1) == 0);
        n = n >> 1;
        result.push((n & 0x1) == 0);
        n = n >> 1;
        result.push((n & 0x1) == 0);
        n = n >> 1;
        result.push((n & 0x1) == 0);
        n = n >> 1;
        result.push((n & 0x1) == 0);
        n = n >> 1;
        result.push((n & 0x1) == 0);
        n = n >> 1;
        result.push((n & 0x1) == 0);
        n = n >> 1;
    }
    return result;
}
export function Price(listing, Arrive, Depart) {
    // ??? calculate in API?
    if (!listing.dailyRates) {
        var numDays = 365;
        var dailyRates = [];
        var minStay = [];
        for (var i = 0; i < numDays; i++) {
            dailyRates[i] = 0;
            minStay[i] = 0;
        }
        if (listing.rates && listing.fees) {
            for (var _i = 0, _a = listing.rates; _i < _a.length; _i++) {
                var rr = _a[_i];
                var startingNdx = datediff(listing.availabilityFirstDate, new Date(rr.startOn));
                var endingNdx = datediff(listing.availabilityFirstDate, new Date(rr.endOn));
                if (startingNdx < 0)
                    startingNdx = 0;
                if (endingNdx >= numDays)
                    endingNdx = numDays - 1;
                var d = addDays(listing.availabilityFirstDate, startingNdx);
                for (var i = startingNdx; i <= endingNdx; i++) {
                    dailyRates[i] = d.getDay() == 5 || d.getDay() == 6 ? rr.weekendRate : rr.weekdayRate;
                    minStay[i] = rr.minimumNights;
                    d = addDays(d, +1);
                }
            }
        }
        listing.dailyRates = dailyRates;
    }
    var result = 0;
    if (Arrive && Depart) {
        var startingNdx = datediff(new Date(listing.availabilityFirstDate), Arrive);
        var endingNdx = datediff(new Date(listing.availabilityFirstDate), Depart);
        if (startingNdx < 0)
            startingNdx = 0;
        if (endingNdx > listing.dailyRates.length)
            endingNdx = listing.dailyRates.length;
        for (var i = startingNdx; i <= endingNdx; i++) {
            result += listing.dailyRates[i];
        }
    }
    if (result == 0)
        return '&nbsp;';
    return "$" + result;
}
