import Vue from 'vue';
import * as types from '@/types';
import { getImageURL } from '@/api';
var carousel = require('vue-carousel');
export default Vue.extend({
    name: 'listing-slider',
    components: { Carousel: carousel.Carousel, Slide: carousel.Slide },
    props: ['images'],
    methods: {
        getImageURL: function (image) {
            return getImageURL(image, types.ImageSize.large);
        },
        getThumbnailURL: function (image) {
            return getImageURL(image, types.ImageSize.tiny);
        },
        goToSlide: function (index) {
            var slider = this.$refs.slider;
            slider.goToPage(index);
        }
    }
});
