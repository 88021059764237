import Vue from 'vue';
export default Vue.extend({
    name: 'location-menu',
    props: ['items'],
    methods: {
        itemClass: function (item) {
            if (this.$route.path === item.slug) {
                return 'active';
            }
            return '';
        },
        itemIcon: function (item) {
            return 'fa-' + item.picture;
        }
    }
});
