import PageComponent from '@/lib/page-component.ts';
import navigation from '@/components/navigation.vue';
import loadingIndicator from '@/components/loadingIndicator.vue';
import layoutFooter from '@/components/layoutFooter.vue';
import layoutHeader from '@/components/layoutHeader.vue';
export default PageComponent.extend({
    components: { navigation: navigation, layoutFooter: layoutFooter, layoutHeader: layoutHeader, loadingIndicator: loadingIndicator },
    metaInfo: {
        title: 'Nomadness Rentals',
        titleTemplate: '%s | Indulge yourself at a quality vacation home…',
    },
});
