var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Vue from 'vue';
import searchBar from '@/components/search-bar.vue';
import listingSlider from './listing-slider.vue';
import api from '@/api';
import store, { setIsLoading, loadHomepage } from '@/store';
import PageComponent from '@/lib/page-component.ts';
import { Price } from '@/lib/property';
import * as quoteLib from '@/lib/quote';
import reservation from "./reservation/index.vue";
var carousel = require('vue-carousel');
export default PageComponent.extend({
    name: 'listing',
    components: { listingSlider: listingSlider, searchBar: searchBar, reservation: reservation, Carousel: carousel.Carousel, Slide: carousel.Slide },
    props: ['id', 'slug'],
    data: function () {
        return {
            listing: null,
            calendarStyles: {
                wrapper: {
                    backgroundColor: 'transparent',
                    color: 'black',
                },
            },
            calendarFormats: {
                title: 'MMMM YYYY',
                weekdays: 'WWW',
                navMonths: 'MMM',
                input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
                dayPopover: 'L',
            },
            calendarAttributes: [
                {
                    key: 'today',
                    highlight: {
                        backgroundColor: '#ff8080',
                    },
                    dates: new Date(2018, 0, 1)
                }
            ],
            discounts: [],
            reviews: []
        };
    },
    methods: {
        formatDate: function (date) {
            if (typeof date === 'string') {
                date = new Date(date);
            }
            return (date.getMonth() + 1) +
                "/" + date.getDate() +
                "/" + date.getFullYear();
        },
        color: function (index) {
            switch (index % 4) {
                case 0: return 'color-2';
                case 1: return 'color-3';
                case 2: return 'color-1';
                case 3: return 'color-4';
                default: return 'color-2';
            }
        },
        calendarDayClass: function (day) {
            if (this.IsAvailable(day.date)) {
                return 'has-events-rentals-free';
            }
            else {
                return 'has-events-rentals';
            }
        },
        IsAvailable: function (date) {
            return quoteLib.IsAvailable({ Listing: this.listing, Arrive: date, Depart: date });
        },
        Price: function (date) {
            return Price(this.listing, date, date);
        },
        StartDayName: function (day) {
            switch (day) {
                case 0: return "Any Day";
                case 1: return "Monday";
                case 2: return "Tuesday";
                case 3: return "Wednesday";
                case 4: return "Thursday";
                case 5: return "Friday";
                case 6: return "Saturday";
                case 7: return "Sunday";
            }
            return "N/A";
        },
        updateHeader: function () {
            if (!this.listing) {
                return;
            }
            this.setTitle('');
            this.setBreadcrumbs([
                { label: 'Home', route: { name: 'home' } },
                { label: 'Properties' },
                // { label: this.listing.location },
                { label: 'Rentals' },
                { label: "Listing " + this.listing.id },
            ]);
        },
        updateReviewScripts: function () {
            var _this = this;
            // Some reviews include custom JS (e.g. Yelp's reviews do that)
            // We need to do extra work in order to evaluate this JS.
            // Note: Yes, this allows third-parties to run code on our site.
            Vue.nextTick(function () {
                var scripts = _this.$el.querySelectorAll('#reviews .review script');
                Array.prototype.forEach.call(scripts, function (script) {
                    var newScript = document.createElement("script");
                    newScript.src = script.getAttribute('src');
                    _this.$el.appendChild(newScript);
                });
            });
        },
    },
    watch: {
        'listing': 'updateHeader',
        'reviews': 'updateReviewScripts',
    },
    beforeRouteEnter: function (to, from, next) {
        return __awaiter(this, void 0, void 0, function () {
            var id, listing, homepage, reviews;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsLoading(store, true);
                        id = parseInt(to.params.id, 10);
                        return [4 /*yield*/, api.listing(id)];
                    case 1:
                        listing = _a.sent();
                        return [4 /*yield*/, loadHomepage(store)];
                    case 2:
                        homepage = _a.sent();
                        return [4 /*yield*/, api.reviews(id)];
                    case 3:
                        reviews = _a.sent();
                        next(function (vm) {
                            vm.$data.listing = listing;
                            vm.$data.discounts = homepage.discounts.filter(function (x) { return x.listingIDs.includes(listing.id.toString()); });
                            vm.$data.reviews = reviews;
                            setIsLoading(store, false);
                        });
                        return [2 /*return*/];
                }
            });
        });
    },
});
