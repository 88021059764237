function datediff(first, second) {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((second.valueOf() - first.valueOf()) / (1000 * 60 * 60 * 24));
}
function addDays(date, days) {
    var dat = new Date(date.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
}
export function IsAvailable(quote) {
    var availability = quote.Listing.availability;
    var firstDate = quote.Listing.availabilityFirstDate;
    if (availability == null) {
        return false;
    }
    if (!quote.Arrive) {
        return true;
    }
    var depart = quote.Depart ? quote.Depart : quote.Arrive;
    var n1 = datediff(firstDate, quote.Arrive);
    var n2 = datediff(firstDate, depart);
    if (n1 < 0 || n2 > availability.length) {
        return false;
    }
    for (var i = n1; i <= n2; i++) {
        if (!availability[i]) {
            return false;
        }
    }
    return true;
}
export function CalcPropertyPriceQuote(quote) {
    var listing = quote.Listing;
    var errors = [];
    var result = {
        errors: errors,
        quotePrice: 0,
        quoteTotalLodging: 0,
        quoteTotalTax: 0,
        quoteTotalFees: 0,
        quoteTotalInsurance: 0,
        travelInsuranceCostPercentage: 0
    };
    if (!quote.Arrive || !quote.Depart) {
        errors.push('Please select a period');
        return result;
    }
    var m_isAvailable = IsAvailable(quote);
    if (!m_isAvailable) {
        errors.push('The dates you have requested are not available');
        return result;
    }
    // TODO: calc listing.dailyRates only ONCE, get corlistingt range on every calc
    var numDays = datediff(quote.Arrive, quote.Depart);
    var dailyRates = [];
    // if (listing.dailyRates) {
    //   dailyRates = listing.dailyRates
    // } else {
    //var minStay = []
    for (var i = 0; i < numDays; i++) {
        dailyRates[i] = 0;
        // minStay[i] = 0
    }
    for (var _i = 0, _a = listing.rates; _i < _a.length; _i++) {
        var rr = _a[_i];
        var startingNdx = datediff(quote.Arrive, new Date(rr.startOn));
        var endingNdx = datediff(quote.Arrive, new Date(rr.endOn));
        if (startingNdx < 0)
            startingNdx = 0;
        if (endingNdx >= numDays)
            endingNdx = numDays - 1;
        var d = addDays(quote.Arrive, startingNdx);
        for (var i = startingNdx; i <= endingNdx; i++) {
            dailyRates[i] = d.getDay() == 5 || d.getDay() == 6 ? rr.weekendRate : rr.weekdayRate;
            // minStay[i] = rr.MinimumNights
            d = addDays(d, 1);
        }
    }
    listing.rates = listing.rates || [];
    listing.fees = listing.fees || [];
    //}
    // console.log(listing.fees);
    var lodgingTax = listing.lodgingTax;
    var serviceTax = listing.serviceTax;
    // console.log(listing.fees.filter( x => x.code == "RESFEE" ))
    var resFee = listing.fees
        .filter(function (x) { return x.code == "RESFEE"; })
        .reduce(function (a, v) { return a + v.amount; }, 0);
    var totalLodging = dailyRates
        .reduce(function (a, v) { return a + v; }, 0);
    // console.log(listing.fees.filter( x => !x.isPercentage && x.code != "PETFEE" && x.code != "ADDGST" ))
    var totalFees = listing.fees
        .filter(function (x) { return !x.isPercentage && x.code != "PETFEE" && x.code != "ADDGST"; })
        .reduce(function (a, v) { return a + v.amount; }, 0);
    var quoteDiscount = 0;
    if (quote.DiscountCode && quote.DiscountCode != "") {
        if (quote.DiscountCode == "test") {
            // TODO: remove test
            quoteDiscount = 50;
        }
        else {
            result.errors.push('Invalid discount code');
            return result;
        }
    }
    var travelInsuranceCostPercentage = 0;
    if (quote.InsuranceCoverage === 'insurance') {
        travelInsuranceCostPercentage = 7;
    }
    if (quote.InsuranceCoverage === 'cfar') {
        travelInsuranceCostPercentage = 9.5;
    }
    var totalIns = listing.damageInsurance;
    var totalTax = Math.round(totalLodging * lodgingTax + (totalFees + totalIns) * serviceTax) / 100.0;
    var totalResFee = Math.round((totalLodging + totalFees + totalTax + totalIns) * resFee) / 100.0;
    totalTax += Math.round(totalResFee * serviceTax) / 100.0;
    totalFees += totalResFee;
    var quotePrice = Math.round((totalLodging + totalFees + totalTax + totalIns - quoteDiscount) * 100) / 100;
    var travelInsuranceCost = quotePrice * travelInsuranceCostPercentage / 100;
    quotePrice += travelInsuranceCost;
    totalIns += travelInsuranceCost;
    result.quotePrice = quotePrice;
    result.quoteDiscount = quoteDiscount;
    result.quoteTotalLodging = totalLodging;
    result.quoteTotalTax = totalTax;
    result.quoteTotalFees = totalFees;
    result.quoteTotalInsurance = totalIns;
    result.travelInsuranceCostPercentage = travelInsuranceCostPercentage;
    return result;
}
