import Vue from 'vue';
var carousel = require('vue-carousel');
export default Vue.extend({
    name: 'discounts',
    components: { Carousel: carousel.Carousel, Slide: carousel.Slide },
    props: ['items'],
    methods: {
        color: function (index) {
            switch (index % 4) {
                case 0: return 'color-2';
                case 1: return 'color-3';
                case 2: return 'color-1';
                case 3: return 'color-4';
                default: return 'color-2';
            }
        }
    },
});
