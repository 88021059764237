var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
// import homepageData from '@/data/homepage'
import * as types from '@/types';
import { parseListing } from '@/lib/property';
// const apiRoot = 'http://office.puldin.eu/NomadnessWebAPI/api'
export var root = 'https://s3.amazonaws.com/nmr-cdn';
export var apiRoot = root + "/api/";
export var imageRoot = root + "/img/";
export var siteName = "nomadness";
export function getImageURL(image, size) {
    if (size === void 0) { size = types.ImageSize.large; }
    return "" + imageRoot + image.listingId + "/" + size + "/" + image.fileName;
}
export function getPageImageURL(pageID, size) {
    if (size === void 0) { size = types.ImageSize.large; }
    return "" + imageRoot + siteName + "/" + pageID + "/" + size + "/1.jpg";
}
var listingAvailability = null;
var api = axios.create({
    baseURL: apiRoot,
});
var lib = {
    location: function (id) {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get(siteName + "/locations/" + id + ".json")];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    locationActivity: function (locationID, id) {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get(siteName + "/locations/" + locationID + "/" + id + ".json")];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    locations: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get(siteName + "/locations/index.json")];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
    // listings are shared - no sitename here
    listingsAvailability: function () {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(listingAvailability == null)) return [3 /*break*/, 2];
                        return [4 /*yield*/, api.get("listings/availability.json")];
                    case 1:
                        listingAvailability = (_a.sent()).data;
                        _a.label = 2;
                    case 2: return [2 /*return*/, listingAvailability];
                }
            });
        });
    },
    // listings are shared - no sitename here
    listings: function () {
        return __awaiter(this, void 0, Promise, function () {
            var listingsRaw, listingAvailability, listings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("listings/index.json")];
                    case 1:
                        listingsRaw = (_a.sent()).data;
                        return [4 /*yield*/, lib.listingsAvailability()];
                    case 2:
                        listingAvailability = _a.sent();
                        listingsRaw = listingsRaw.map(function (l) {
                            l.availability = listingAvailability[l.id];
                            l.availabilityFirstDate = new Date(listingAvailability['@']);
                            return l;
                        });
                        listings = listingsRaw.map(parseListing);
                        return [2 /*return*/, listings];
                }
            });
        });
    },
    // listings are shared - no sitename here
    listing: function (id) {
        return __awaiter(this, void 0, Promise, function () {
            var listingRaw, images, listingAvailability, listing;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("listings/" + id + ".json")];
                    case 1:
                        listingRaw = (_a.sent()).data;
                        return [4 /*yield*/, api.get("" + imageRoot + id + "/index.json")];
                    case 2:
                        images = (_a.sent()).data;
                        return [4 /*yield*/, lib.listingsAvailability()];
                    case 3:
                        listingAvailability = _a.sent();
                        listingRaw.images = images;
                        listingRaw.availability = listingAvailability[listingRaw.id];
                        listingRaw.availabilityFirstDate = new Date(listingAvailability['@']);
                        listing = parseListing(listingRaw);
                        return [2 /*return*/, listing];
                }
            });
        });
    },
    // listings are shared - no sitename here (not so sure about the reviews ...)
    reviews: function (id) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get("listings/" + id + "-reviews.json")];
                    case 1: return [2 /*return*/, (_a.sent()).data];
                }
            });
        });
    },
    // listings are shared - no sitename here
    discount: function (id) {
        return __awaiter(this, void 0, Promise, function () {
            var discount, listings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get(siteName + "/discounts/" + id + ".json")];
                    case 1:
                        discount = (_a.sent()).data;
                        return [4 /*yield*/, lib.listings()];
                    case 2:
                        listings = _a.sent();
                        discount[0].listings = discount[0].listingIDs.map(function (listing_id_str) {
                            var listing_id = parseInt(listing_id_str);
                            var ll = listings.find(function (x) { return x.id == listing_id; });
                            if (ll == null)
                                return null;
                            var listing = JSON.parse(JSON.stringify(ll));
                            if (listing)
                                listing.options = { featured: false, discounted: false, petsAllowed: listing.options.petsAllowed };
                            return listing;
                        }).filter(function (x) { return x != null; });
                        return [2 /*return*/, discount[0]];
                }
            });
        });
    },
    homepage: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data, listings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get(siteName + "/pages/home.json")];
                    case 1:
                        data = (_a.sent()).data;
                        data.testimonials = data.subPages;
                        return [4 /*yield*/, this.listings()];
                    case 2:
                        listings = _a.sent();
                        data.featured_properties = listings.filter(function (x) { return x.options.featured; });
                        // data.testimonials = (data as any).subPages.map((page:homepageTypes.Testimonial, index:number) => {
                        //   //let t = homepageData.testimonials[index]
                        //   //page.image = t.image
                        //   return page
                        // })
                        // TODO : populate from listings
                        //data.featured_properties = homepageData.featured_properties
                        return [2 /*return*/, data];
                }
            });
        });
    },
    aboutpage: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get(siteName + "/pages/about.json")];
                    case 1:
                        data = (_a.sent()).data;
                        data.team = data.subPages.map(function (page, index) {
                            //let t = homepageData.team[index]
                            //page.image = t.image
                            return page;
                        });
                        return [2 /*return*/, data];
                }
            });
        });
    },
    contactpage: function () {
        return __awaiter(this, void 0, Promise, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, api.get(siteName + "/pages/contact.json")];
                    case 1:
                        data = (_a.sent()).data;
                        return [2 /*return*/, data];
                }
            });
        });
    },
};
export default lib;
